import React from 'react';
import { Box, Divider, FormControl } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { SecondaryButton } from '../../../components/secondary-button/secondary-button';
import { RichTextEditor } from '../../../components/rich-text-editor/rich-text-editor';
import { ObituaryStatus } from '../../../model/obituary/obituary.store';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { SectionHeadline } from '../../../components/section-headline/section-headline';
import { FormTextField } from '../../../components/form-text-field/form-text-field';
import { ConfirmationModal } from '../../../components/confirmation-modal/confirmation-modal';
import { FieldErrorMessage } from '../../../components/field-error-message/field-error-message';
import {
    ObituaryFormValues,
    ObituaryQuestionAndAnswer,
    ObituaryUnforgettableQuote,
    ObituaryWritingStyle,
} from '../../model/obituary.types';

import { ObituaryUnforgettableQuoteSelector } from './components/obituary-unforgettable-quote-selector/obituary-unforgettable-quote-selector';
import { ObituaryWritingStyleSelector } from './components/obituary-writing-style-selector/obituary-writing-style-selector';
import { ObituaryQuestionsAndAnswers } from './components/obituary-questions-and-answers/obituary-questions-and-answers';
import { ObituaryLoading } from './components/obituary-loading/obituary-loading';
import { useObituaryFormStyles } from './obituary-form.styles';
import { useObituaryForm } from './use-obituary-form';
import {
    mapObituaryFormPropsToObituaryFormValues,
    validateObituaryFormValues,
} from './obituary-form.helpers';
import { DeceasedReligionSelectInput } from './components/deceased-religion-select-input';

export type ObituaryFormOuterProps = {
    deceasedName: string;

    obituaryText: string;
    generatedObituary: string;

    obituaryLoading: boolean;
    obituaryStatus: ObituaryStatus | null;
    obituaryStatusMessage: string | null;

    onGenerate(values: ObituaryFormValues): unknown;
    onSubmit(values: ObituaryFormValues): unknown;
};

export type ObituaryFormProps = FormikProps<ObituaryFormValues> &
    ObituaryFormOuterProps;

export const ObituaryForm = (props: ObituaryFormProps) => {
    const { t } = useTranslation();
    const styles = useObituaryFormStyles();
    const mobile = useIsMobile();

    const {
        values,
        touched,
        isValid,
        errors,
        shouldShowError,

        handleChange,
        handleValueChange,
        handleBlur,

        obituaryStatus,
        obituaryStatusMessage,
        obituaryLoading,
        obituaryInputRef,

        isConfirmationModalOpened,
        handleClickSubmit,
        handleRequestGenerate,
        handleConfirmGenerate,
        handleCancelGenerate,
    } = useObituaryForm(props);

    const customUnforgettableQuoteThemeInputError = Boolean(
        touched.obituaryUnforgettableQuote &&
            touched.obituaryUnforgettableQuote.info &&
            errors.obituaryUnforgettableQuote &&
            errors.obituaryUnforgettableQuote.info,
    );

    return (
        <form>
            <Box mb={6}>
                <Box mb={4}>
                    <SectionHeadline>
                        {t('obituary.deceased.headline')}
                    </SectionHeadline>
                </Box>
                <Box mb={3}>
                    <FormControl
                        fullWidth
                        error={shouldShowError('deceasedPreferredName')}
                    >
                        <FormTextField
                            required
                            error={shouldShowError('deceasedPreferredName')}
                            name="deceasedPreferredName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.deceasedPreferredName}
                            label={t('obituary.deceased.preferredName')}
                        />
                        <FieldErrorMessage name="deceasedPreferredName" />
                    </FormControl>
                </Box>
                <Box>
                    <DeceasedReligionSelectInput
                        name="deceasedReligion"
                        value={values.deceasedReligion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <Divider light />
                </Box>
                <ObituaryQuestionsAndAnswers
                    obituaryQuestionsAndAnswers={
                        values.obituaryQuestionsAndAnswers
                    }
                    onObituaryQuestionsAndAnswersChange={handleValueChange<
                        ObituaryQuestionAndAnswer[]
                    >('obituaryQuestionsAndAnswers')}
                />
                <Box mt={4} mb={4}>
                    <Divider light />
                </Box>
                <ObituaryWritingStyleSelector
                    obituaryWritingStyle={values.obituaryWritingStyle}
                    onObituaryWritingStyleChange={handleValueChange<
                        ObituaryWritingStyle
                    >('obituaryWritingStyle')}
                />
                <Box mt={4} mb={4}>
                    <Divider light />
                </Box>
                <ObituaryUnforgettableQuoteSelector
                    obituaryUnforgettableQuote={
                        values.obituaryUnforgettableQuote
                    }
                    onObituaryUnforgettableQuoteChange={handleValueChange<
                        ObituaryUnforgettableQuote
                    >('obituaryUnforgettableQuote')}
                    customUnforgettableQuoteThemeInputError={
                        customUnforgettableQuoteThemeInputError
                    }
                    customUnforgettableQuoteThemeInputName="obituaryUnforgettableQuote.info"
                    handleCustomUnforgettableQuoteThemeBlur={handleBlur}
                />
                <Box mt={4} mb={4}>
                    <Divider light />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <SecondaryButton
                        disabled={obituaryLoading || !isValid}
                        fullWidth={mobile}
                        onClick={handleRequestGenerate}
                    >
                        {t('obituary.generate')}
                    </SecondaryButton>
                </Box>
            </Box>
            <Box mb={6}>
                <Box mb={2}>
                    <SectionHeadline>
                        {t('mediaAndContent.obituary.obituaryText')}
                    </SectionHeadline>
                </Box>
                <Box className={styles.obituaryTextContainer}>
                    <ObituaryLoading
                        obituaryLoading={obituaryLoading}
                        obituaryStatus={obituaryStatus}
                        obituaryStatusMessage={obituaryStatusMessage}
                    />
                    <RichTextEditor
                        className={cx(styles.obituaryTextEditor, {
                            [styles.hiddenObituaryTextEditor]: !!obituaryStatusMessage,
                        })}
                        disabled={obituaryLoading}
                        ref={obituaryInputRef}
                        name="obituaryText"
                        onChange={handleChange}
                        value={values.obituaryText}
                        minHeight="50rem"
                        toolbarSetup={[
                            [{ header: [3, false] }],
                            ['bold', 'italic', 'underline', 'strike'],
                        ]}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={5}>
                <SecondaryButton
                    fullWidth={mobile}
                    onClick={handleClickSubmit}
                    disabled={obituaryLoading}
                >
                    {t('common.save')}
                </SecondaryButton>
            </Box>
            <ConfirmationModal
                onConfirm={handleConfirmGenerate}
                onCancel={handleCancelGenerate}
                open={isConfirmationModalOpened}
                confirmButtonType="secondary"
                cancelButtonType="secondaryText"
                description={t('mediaAndContent.obituary.generate.warning')}
                maxWidth="sm"
            />
        </form>
    );
};

export const ConnectedObituaryForm = compose<
    ObituaryFormProps,
    ObituaryFormOuterProps
>(
    withFormik<ObituaryFormOuterProps, ObituaryFormValues>({
        handleSubmit() {},
        mapPropsToValues: mapObituaryFormPropsToObituaryFormValues,
        validate: validateObituaryFormValues,
    }),
)(ObituaryForm);
